import React from 'react'
import './Home.css'
import styled from 'styled-components'
import bg from './homebg.png'
import mbg from './bg2.png'
import right from './homeright.svg'
import img1 from './b1.svg'
import img2 from './b2.svg'
import img3 from './b3.svg'
import { motion } from 'framer-motion'
import Title from './title.svg'
import Scroll from './scroll.svg'
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from 'react-alice-carousel';

const HomePage = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 100vh;
    width: 100%;
    background-image: url(${bg});
    background-size: 100% 105%;
    background-repeat: no-repeat;
    position: relative;   // z-index works with position like absolute,fixed or relative //
    z-index: -2;
    @media only screen and (max-width: 900px) {
        min-height: 50rem;
        background-image: url(${mbg});
        width: 100%;
        padding: 0rem 0.5rem 2rem 0.5rem;
        background-size: auto 100%;
        flex-direction: column;
        margin-top: 2rem;
    }
    
`;

const Left = styled.div`
        display: flex;
        flex-direction: column;
        height: auto;
        width: 30%;
        position: fixed;
        left: 10%;
        z-index: -1;
@media only screen and (max-width: 768px) {
        padding: 2rem;
        width: 100%;
        position: relative;
        left: 0;
        // text-align: center;
        // align-items: center;
    }
    @media only screen and (max-width: 600px) {
        padding: 2rem;
        width: 100%;
        position: relative;
        left: 0;
        text-align: center;
        align-items: center;
    }
`;

const Right = styled(motion.div)`
    width: 45rem; 
    height: 45rem;
    background-image: url(${right});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: fixed;
    z-index: -1;
    right: 15%;
    top: 10rem;
    display: block;
    transition:  0.5s linear;
    display: flex;
        flex-direction: column;
        align-items: center;

    @media only screen and (max-width: 1800px) {
        right: 14%;
        width: 40rem; 
        height: 40rem;
        top: 11rem;
        transition:  0.5s linear;
    }
    
    @media only screen and (max-width: 1600px) {
        right: 14%;
        width: 40rem; 
        height: 40rem;
        top: 11rem;
        transition:  0.5s linear;
    }
  
    @media only screen and (max-width: 1400px) {
        right: 17%;
        top: 14rem;
        transition:  0.5s linear;
        width: 32rem; 
        height: 32rem;
    }
    @media only screen and (max-width: 1200px) {
        right: 18%;
        top: 30vh;
        width: 28rem; 
        height: 28rem;
        transition:  0.5s linear;
    }
    @media only screen and (max-width: 1200px) {
        right: 13%;
        top: 30vh;
        width: 28rem; 
        height: 28rem;
        transition:  0.5s linear;
    }
    @media only screen and (max-width: 900px) {
        right: 15%;
        top: 30vh;
        width: 24rem; 
        height: 24rem;
        transition:  0.5s linear;
    }
    @media only screen and (max-width: 800px) {
        right: 12%;
        top: 30vh;
        width: 24rem; 
        height: 24rem;
        transition:  0.5s linear;
    }

    @media only screen and (max-width: 768px) {
        width: 85%;
        height: 25rem;
        position: relative;
        right: 0;
        display: none;
    }
    
`;

const HeroImg = styled.div`
    width: 30rem;
    margin-bottom: 1.5rem;
    transition:  0.5s linear;
    @media only screen and (max-width: 1600px) {
        width: 20rem;
        transition:  0.5s linear;
    }
    @media only screen and (max-width: 1400px) {
        width: 19rem;
        transition:  0.5s linear;
    }
    @media only screen and (max-width: 1400px) {
        width: 18rem;
        transition:  0.5s linear;
    }
    @media only screen and (max-width: 1200px) {
        width: 16rem;
        transition:  0.5s linear;
    }
    @media only screen and (max-width: 768px) {
        width: 15rem;
        transition:  0.5s linear;
      }
`;

const T = styled.p`
    color: #FFF;
    font-weight: 500;
    font-size: 1.6rem;
    margin-bottom: 2rem;
    font-weight: bold;
    @media only screen and (max-width: 1200px) {
        font-weight: 510;
        font-size: 1rem;
        text-align: left;
      }
    @media only screen and (max-width: 1000px) {
        font-weight: 510;
        font-size: 1rem;
        text-align: left;
      }
    @media only screen and (max-width: 600px) {
        font-weight: 510;
        font-size: 1rem;

      }
`;

const ScrollContainer = styled(motion.div)`
      width: 1rem;
      height: 15rem;
      position: absolute;
      bottom: 3rem ;
      left: 4rem;
      display: block;

      @media only screen and (max-width: 768px) {
        bottom: 1rem ;
        left: 1rem;
        display: none;
      }
`
const Mobile = styled.div`
    display: none;
    @media only screen and (max-width: 768px) {
        display: flex;
        width: 100%;
        height: auto;
      }
`;

const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    768: { items: 1 },
    900: { items: 1 },
    1000: { items: 1 },
    1024: { items: 1 },

};

const Home = () => {
    return (
        <HomePage id="home">
            <Left>
                <HeroImg>
                    <img src={Title} alt="" style={{width:"100%",height:"100%"}} />
                </HeroImg>
                <T>A collection of 7,420 unique digital collectibles brought to life on the Ethereum blockchain.</T>
                <T >After carefully observing the crypto-sphere, Intellects have decided to make their presence known. What comes next could be legendary... </T>
                <T >WE ARE HERE, WE ARE INTELLECTS</T>
            </Left>
            <Right
                initial={{ x: 0, y:0}}
                animate={{ x: 0, y: -15 }}
                transition={{ duration: 1, repeat: Infinity ,repeatType: "reverse",
               
                }}
            />

            <ScrollContainer
                initial={{ x: 0, y:0}}
                animate={{ x: 0, y: -15 }}
                transition={{ duration: 1, repeat: Infinity ,repeatType: "reverse",
               
                }}
            >
                <img src={Scroll} alt="" style={{width:"100%",height:"100%"}} />
            </ScrollContainer>

            <Mobile>
            <AliceCarousel
                // paddingLeft={12}
                autoPlay={true}
                infinite={true}
                animationDuration={1700}
                disableButtonsControls={true}
                disableDotsControls={true}
                responsive={responsive}
            >
                <img src={img1}  className="yclass"/>
                <img src={img2}  className="tclass"/>
                <img src={img3}  className="yclass"/>
             </AliceCarousel>
            </Mobile>
            
                {/* <img src={right} alt="" style={{width:"100%",height:"100%"}} /> */}
            {/* </Right> */}
        </HomePage>
    )
}

export default Home
