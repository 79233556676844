import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux';
import store from './redux/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UseWalletProvider } from 'use-wallet';

ReactDOM.render(<Provider store={store}>
    <UseWalletProvider
        chainId={1}
        connectors={{
            // This is how connectors get configured
            portis: {  },
        }}
    >
        <App />
        <ToastContainer />
    </UseWalletProvider>

</Provider>, document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
