import React from 'react'
import styled from 'styled-components'
import logo from './logo.svg';
import twitter from './twitter.svg'
import opensea from './opensea.svg'
import { Link, animateScroll as scroll } from "react-scroll";
import { motion } from 'framer-motion';

const Foot = styled.div`
    background-color: #002c65;
    min-height: 9rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 10% 0.5rem 10%;
    @media only screen and (max-width: 1400px) {
        padding: 1.5rem 2rem 0.5rem 2rem;
    }
  
    @media only screen and (max-width: 1000px) {
        flex-direction: column;
        min-height: auto;
        padding: 1.5rem;
   }
`;

const Link1 = styled(Link)`
    display: flex;
    margin: 0 2rem;
    align-items: center;
    text-decoration: none;
    color: #FFF;
    cursor: pointer;


      
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;

     &:hover, &:focus,&:active {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
      }


    @media only screen and (max-width: 768px) {
         margin: 0 1rem;
    }
`;

const Link2 = styled(Link)`
    display: flex;
    margin: 0 2rem;
    align-items: center;
    text-decoration: none;
    color: #FFF;
    
    font-size: 1.5rem;
    cursor: pointer;


    
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: transform;
        transition-property: transform;
     
        &:hover, &:focus, &:active {
        -webkit-transform: translateX(8px);
        transform: translateX(8px);
      }



    @media only screen and (max-width: 768px) {
         margin: 0 1rem;
         font-size: 0.8rem;
    }
    list-style: none;
`;

const Ul = styled.ul`
    display: flex;
    margin: 0 0 0 0;
    @media only screen and (max-width: 1000px) {
        margin: 2rem 0 0 0;
   }
`;

const Right = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 0 0;
    align-items: center;
    @media only screen and (max-width: 1000px) {
        margin: 2rem 0 2rem 0;
   }
`;

const Img = styled(motion.img)`
    width: 3rem;
    cursor: pointer;
`
const T = styled.p`
    color: #FFF;
    font-weight: 500;
    font-size: 16px;
    margin-top: 1rem;
    @media only screen and (max-width: 1100px) {
        font-weight: 510;
        font-size: 0.8rem;
        
      }
    text-align: center;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 0.8rem;
        
      }
`;

const Flex = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 70%;
`;

const Footer = () => {
    return (
        <Foot>
            <Link1 to="home" spy={true} smooth={true}><img src={logo} alt="Logo" /></Link1>
            <Ul>
                <Link2 to="home" spy={true} smooth={true} ><li>Home</li></Link2>
                <Link2 to="intellect" spy={true} smooth={true} offset={-50} ><li>Buy Intellects</li></Link2>
                <Link2 to="roadmap" spy={true} smooth={true} ><li>Roadmap</li></Link2> 
            </Ul> 
            <Right>
                <Flex>
                    <Img
                        whileHover={{ scale: 1.2}}
                        whileTap={{ scale: 0.7 }}
                    src={twitter} alt="" />
                    <Img 
                        whileHover={{ scale: 1.2}}
                        whileTap={{ scale: 0.7 }}
                    src={opensea} alt="" />
                </Flex>
                <T>© Intellects NFT All Right Reserved.</T>
            </Right>
        </Foot>
    )
}

export default Footer
