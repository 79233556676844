import React, { useState, useEffect } from "react";
import "./Page2.css";
import styled from "styled-components";
import bg from "./bg2.png";
import { FcPlus } from "react-icons/fc";
import { FaMinusCircle } from "react-icons/fa";
import { motion } from "framer-motion";
import mint from "./mint.png";
import { data } from "../config/config";
import { data2 } from "../config/config2";
import InvisibleMCard from "../../components/Card/Card";
import InvisibleCard from "../../components/Card/InvisibleCard";
import Brain from "./brain.svg";
import { useSelector } from "react-redux";
import { useWallet } from "use-wallet";
import Web3 from "web3";
const Page = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
  background-image: url(${bg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 8rem 0 3rem 0;
  @media only screen and (max-width: 768px) {
    min-height: 100vh;
    width: 100%;
    padding: 2rem 0.5rem 2rem 0.5rem;
    background-size: auto 100%;
    flex-direction: column;
  }
`;
const BigCard = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #003070;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  padding: 80px 200px 68px 200px;
  border-radius: 2rem;
  margin-bottom: 100px;
  @media only screen and (max-width: 1000px) {
    width: 95%;
    padding: 2rem 2rem;
  }
  @media only screen and (max-width: 700px) {
    width: 95%;
    padding: 2rem 1rem;
  }
`;
const H = styled.h2`
  color: #fff;
  font-weight: bolder;
  font-size: 3rem;
  @media only screen and (max-width: 768px) {
    font-weight: 550;
    font-size: 1.2rem;
    text-align: center;
  }
`;

const Hu = styled.h2`
  color: #fff;
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 3rem;
  @media only screen and (max-width: 768px) {
    font-weight: bold;
    font-size: 1.3rem;
    text-align: center;
  }
`;

const T = styled.p`
  color: #fff;
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  @media only screen and (max-width: 768px) {
    font-weight: bold;
    font-size: 1.5rem;
  }
`;

const Tu = styled.p`
  color: #fbcf2c;
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 20px;
  @media only screen and (max-width: 768px) {
    font-weight: bold;
    font-size: 1rem;
  }
`;

const Td = styled.p`
  color: #fbcf2c;
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 20px;
  @media only screen and (max-width: 768px) {
    font-weight: bold;
    font-size: 1rem;
  }
`;

const SmBox = styled.div`
  margin: 0 2rem 0 0;
  width: 14rem;
  @media only screen and (max-width: 768px) {
    margin: 2rem 0 0 0;
    width: 100%;
  }
`;

const Upper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
  width: 100%;
  border-radius: 1rem 1rem 0 0;
  padding: 0 0.5rem;
  height: 4rem;
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    justify-content: center;
    height: 4rem;
  }
`;

const Lower = styled.div`
  display: grid;
  place-items: center;
  background-color: #c6250a;
  width: 100%;
  border-radius: 0 0 1rem 1rem;
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const BigBox = styled.div`
  min-width: 15rem;
  min-height: 8rem;
  border: 2px solid #013c8a;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  margin-top: 0rem;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    min-height: auto;
    margin-top: 2rem;
    padding: 2rem 0;
    width: 100%;
  }
`;

const Divider = styled.div`
  height: 6rem;
  width: 3px;
  background-color: #013c8a;
  margin: 0 1rem;
  @media only screen and (max-width: 768px) {
    margin: 2rem 0 0 0;
    flex-direction: column;
    width: 100%;
    height: 2px;
  }
`;
const Left = styled.div`
  margin: 0 1rem 0 0;
  @media only screen and (max-width: 768px) {
    margin: 0 0 0 0;
    display: grid;
    place-items: center;
  }
`;

const Right = styled.div`
  margin: 0 0 0 1rem;
  @media only screen and (max-width: 768px) {
    margin: 2rem 0 0 0;
    display: grid;
    place-items: center;
  }
`;

const BoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin: 60px 0;
  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
    margin: 0;
  }
`;

const ButtonBack = styled.div`
  background-color: #deba27;
  height: 3.4rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  border-radius: 0.7rem;
  min-width: 15rem;
  @media only screen and (max-width: 768px) {
    height: 3rem;
    margin-top: 2rem;
    width: 100%;
  }
`;

const Button = styled(motion.div)`
  background-color: #deba27;
  color: #013c8a;
  height: 3rem;
  border: none;
  border-bottom: 2px solid #e5e5e5;
  border-radius: 0.7rem;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0 1rem;
  display: grid;
  place-items: center;
  padding-top: 0.5rem;
  width: auto;
  @media only screen and (max-width: 768px) {
    height: 2.7rem;
  }
`;
const Grid = styled.div`
  display: grid;
  width: 80%;
  height: auto;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  place-items: center;
  position: relative;
  z-index: 4;
  grid-gap: 30px;
  @media only screen and (min-width: 1100px) {
    width: 100%;
    grid-template-columns: repeat(4, minmax(20%, 1fr));
    grid-gap: 10px;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const MobileGrid = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    display: grid;
    width: 100%;
    height: auto;
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
    place-items: center;
    position: relative;
    z-index: 4;
  }
`;

const ImgContainer = styled.div`
  position: absolute;
  right: 0;
  width: 40rem;
  margin-top: 20%;
  z-index: 1;
  display: block;
  @media only screen and (max-width: 768px) {
    width: 15rem;
    display: none;
  }
`;

const Page2 = () => {
  const mydata = useSelector((state) => state.mydata);
  const account1 = useSelector((state) => state.account);
  const contract = useSelector((state) => state.contract);
  const [priceForNft, setpriceForNft] = useState(0);
  const [value, setvalue] = useState(0);
  const { account, connect } = useWallet();
  const [remainingAmount, setRemainingAmount] = useState();

  const handleMint = async () => {
    console.log("sssasasa", value, priceForNft);

    await contract.methods.mint(value).send({
      from: account,
      value: Web3.utils.toWei((value * 0.042).toString()),
    });
  };

  const [noOfNFT, setNoOfNFT] = useState(null);

  useEffect(() => {
    connect();
  }, []);


  const loadData = async()=>{
      let totalSupply = await contract.methods.totalSupply().call()
      setRemainingAmount(7420-totalSupply)
  }
  useEffect(()=>{
    if(contract){
        loadData()
    }
  },[contract])

  return (
    <Page id="intellect">
      <BigCard>
        {remainingAmount ? (
          <H>
            <span style={{ fontWeight: "bold" }}>{remainingAmount}</span>{" "}
            Intellects Remaining
          </H>
        ) : null}

        <BoxContainer>
          <SmBox>
            <Upper>
              <FaMinusCircle
                className="iconL"
                onClick={() => {
                  if (noOfNFT > 0) {
                    setNoOfNFT(noOfNFT - 1);
                  }
                }}
              />

              <input
                placeholder="No.s of NFT"
                value={noOfNFT}
                style={{
                  width: "70%",
                  height: "60%",
                  border: "none",
                  fontSize: "1.5rem",
                  paddingLeft: "0.5rem",
                }}
                onChange={(e) => {
                  setpriceForNft(parseInt(e.target.value) * mydata?.price || 0);
                  setNoOfNFT(parseInt(e.target.value));
                  setvalue(e.target.value);
                }}
                type="number"
              />

              <FcPlus
                className="iconR"
                onClick={() => {
                  if (noOfNFT < 20) {
                    setNoOfNFT(noOfNFT + 1);
                  }
                }}
              />
            </Upper>
            <Lower>
              <T style={{ margin: "1rem 0" }}>Max 42</T>
            </Lower>
          </SmBox>
          <BigBox>
            <Left>
              <Tu>COST</Tu>
              <Hu style={{ margin: "0" }}>
                {mydata?.price / 10 ** 18 || 0.042} eth
              </Hu>
            </Left>
            <Divider />
            <Right>
              <Td>TOTAL COST</Td>
              <Hu style={{ margin: " 0" }}>{priceForNft / 10 ** 18} + GAS</Hu>
            </Right>
          </BigBox>
        </BoxContainer>

        {account ? (
          <ButtonBack>
            <Button onClick={() => handleMint()}>MINT</Button>
          </ButtonBack>
        ) : (
          <ButtonBack>
            <Button onClick={() => connect()}>Connect</Button>
          </ButtonBack>
        )}
      </BigCard>

      <Grid>
        {/* {data?.map(e => (
                <Card 
                    bg={e.img}
                    name={e.name}
                    L1={e.L1}
                    L2={e.L2}
                    L3={e.L3}
                /> 
            ))} */}
        {data?.map((e) => (
          <InvisibleMCard
            img={e.img}
            name={e.name}
            L1={e.L1}
            L2={e.L2}
            L3={e.L3}
            L4={e.L4}
          />
        ))}
      </Grid>

      <MobileGrid>
        {data2?.map((e) => (
          <InvisibleCard
            img={e.img}
            name={e.name}
            L1={e.L1}
            L2={e.L2}
            L3={e.L3}
            L4={e.L4}
          />
        ))}
      </MobileGrid>

      {/* <ImgContainer>
                <img src={Brain} alt="" style={{width:"100%",height:"100%"}} />
            </ImgContainer>  */}
    </Page>
  );
};

export default Page2;
